body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  color:rgb(150, 150, 150);
  width:100%;
}


.content {
  text-align: center;
  /* padding-top: 6rem; */
  width: 100%;
  z-index: -1;
}

.Site-header{
  color: #61dafb;
  font-weight: 600;
  width: 100%;
  top: 0;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position:fixed;
  background-color: white;
  box-shadow: rgb(0 0 0 / 7%) 0px 3px 4px;
}

.Site-header-left{
  float: left;
  display:inline;
  /* margin-top: -1.8rem; */
  
}

.Site-header-right{
  float: right;
  display:inline;
  /* margin-top: -1.8rem; */
  text-align: right !important;
}

.Site-logo{  
  width:10rem;
  margin-top: -2.2rem;
  margin-bottom: -2rem;
}

.Header-menu{
  display: block;
  padding-top: 1em;
  padding: 0px;
  margin: 0px;
  text-align: center;
  display:inline-block;
}

.Header-menu li{
  float: right;
  list-style-type: none;
  padding: 1rem;
  display: inline;
  
}


.Home-image {
  width: 100%;  
  background-image: url("./bg2.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  margin-top:'-4rem';
  margin-left: 0rem;
  margin-right: 0rem;
  display: flex;
}




#wd-center{
  color:rgb(24, 43, 73);
  text-align: center;
  width: auto;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 3rem;
  z-index: -1;
  margin-left: 15%;
  margin-right: 15%;
}


.Footer{
  /* position: fixed;
  bottom: 0; */
  margin-top: 2px;
  padding-top: 2rem;
  padding-bottom: 3rem;
  display: flex;
  width: 100%;
  height: auto;
  background-color: #05637d;
  color:#dfdfdf;
  width:100%;
  font-size: 14px;
}

.nav-link{
  text-decoration: none;
}

.nav-link:hover{
  font-size: 14px;
}

.Footer .nav-link{
  text-decoration: none;
  color:#dfdfdf;
  
}

.Footer .nav-link:hover{
  text-decoration: underline;
  color:white;
  font-size: 14px;
}

.Footer-logo{
  width: 10rem;
  background-color: white;
  color: white;
  border-radius: 10% 100% 80% 50%;
}



.Footer-section-wd{
  display: block;
  width: 100%;
  border-top-color:whitesmoke;
  border-top-style: solid;
  border-top-width: 1px;
}

.about-right-span{
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  color: #078fc3;
}

.item{
  position: absolute;  
  width: 20px;
  height: 20px;
  background: #078fc3;
  /* top: 1.7rem;
  left: 1rem; */
  border-radius: 30% 0px 30% 0%;
  transform: rotate(45deg);
  z-index: -1;
  /* margin-right: 0px; */

}




@media only screen and (max-width:3800px) {
  .Home-section_l,.Home-section_r{
    text-align: left;
    margin: 2rem;
    display: inline;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    color: rgb(150, 150, 150); 
    z-index: -1;
        
  }
  /* For tablets: */
  .Footer-section{
    display: inline;  
    width: 33%;
    text-align: justify;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .about-right{
    width: 30%;
    height: 25rem;
    background-color: #5a270238;
    z-index: -1;
    /* border-radius: tl te br bl; */
    border-radius: 20% 20% 100% 20%;
    /* border-radius: 20% 40% 70px 100%; */
    padding-top: 5rem;
    padding-left: 5rem;
    margin-bottom: 2rem;
    display: inline;
  
    position: relative;
    -webkit-animation-name: exampleAbout; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
    animation-name: exampleAbout;
    animation-duration: 8s;
  }
  
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes exampleAbout {
    0%   {background-color:#dfdfdf17; left:200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }
  
  /* Standard syntax */
  @keyframes exampleAbout {
    0%   {background-color:#dfdfdf17; left:200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }
  
  .about-right-down{
    z-index: -1;
    width: 30%;
    height: 25rem;
    background-color: #5a270238;
    border-radius: 20% 20% 20% 100%;
    padding-top: 5rem;
    padding-left: 5rem;
    margin-bottom: 2rem;
    display: inline;
    text-align: left;
    padding-left: 5rem;
    padding-right: 5rem;
  
    position: relative;
    -webkit-animation-name: exampleAboutDown; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
    animation-name: exampleAboutDown;
    animation-duration: 8s;
  
  }
  
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes exampleAboutDown {
    0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }
  
  /* Standard syntax */
  @keyframes exampleAboutDown {
    0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }

  .about-left{
    text-align: left;
    padding-left: 5rem;
    padding-right: 5rem;
    width: 50%;
    display: inline;
    z-index: -1;
  }

  
.page-title{
  text-align: left;
    padding-left: 6rem;
    padding-right: 6rem;
    margin-left: 0%;
    margin-right: 0%;
}

  

.image-left {
  font-size: 4rem;
  color:white;
  width: 50%;
  text-align: start;
  padding-top: 5rem;
  padding-left: 5rem;
  display: inline;
  
}

.image-right{
  content: none;
  display: inline;
}

.Home-section_l {
  width: 50%;   
  background: rgb(255, 255, 255);
  z-index: 9;
  border: solid 1px #dfdfdf;
  /* box-shadow: rgb(0 0 0 / 7%) 0px 4px 8px; */
  border-radius: 5px;  

  background-color: #dfdfdf17;
  background: linear-gradient(to bottom right, #61dafb 0%, #61dafb 5%, #fff 5%, #fff 100%);
  position: relative;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 8s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* Standard syntax */
@keyframes example {
  /* 0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  10%   {background-color:#dfdfdf17; top:20px;}
  25%  {background-color:#dfdfdf17;  top:-20px;}
  100%  {background-color:#dfdfdf17; left:0px; top:0px;} */
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

.Home-section_r {
  width: 50%;   
  background: rgb(255, 255, 255);
  z-index: 9;
  border: solid 1px #dfdfdf;
  /* box-shadow: rgb(0 0 0 / 7%) 0px 4px 8px; */
  border-radius: 5px;  

  background-color: #dfdfdf17;
  background: linear-gradient(to bottom right, #61dafb 0%, #61dafb 5%, #fff 5%, #fff 100%);
  

  position: relative;
  -webkit-animation-name: example4; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
  animation-name: example4;
  animation-duration: 8s;
}



.Home-section-wd{
  display: inline-flex;
  padding-left: 20rem;
  padding-right: 20rem;
  position: relative;
  z-index: -1;
}

.about-content{
  display: flex;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: 0%;
  margin-right: 0%;
}


}
@media only screen and (max-width:500px) {
  .Header-menu .menu-links{
    display:none;
  }

  .about-content{
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0%;
  margin-right: 0%;
  }

  .Home-section_l,.Home-section_r{
    text-align: left;
    margin-left: 0rem;
    margin-right: 0rem;
    width: auto;
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    color: rgb(150, 150, 150); 
    z-index: -1;
        
  }
  
  /* For mobile phones: */
  .Footer{
    display: block;
  }
  .Footer-section{
    display: block;  
    width: 100%;
    text-align: justify;
    padding-left: 1rem;
    padding-right: 0rem;
    margin-bottom: 2rem;
  }

  .about-right{
    width: 80%;
    height: 25rem;
    background-color: #5a270238;
    z-index: -1;
    /* border-radius: tl te br bl; */
    border-radius: 20% 20% 100% 20%;
    /* border-radius: 20% 40% 70px 100%; */
    padding-top: 5rem;
    padding-left: 5rem;
    margin-bottom: 2rem;
    display: block;
  
    position: relative;
    -webkit-animation-name: exampleAbout; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
    animation-name: exampleAbout;
    animation-duration: 8s;
  }
  
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes exampleAbout {
    0%   {background-color:#dfdfdf17; left:200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }
  
  /* Standard syntax */
  @keyframes exampleAbout {
    0%   {background-color:#dfdfdf17; left:200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }
  
  .about-right-down{
    z-index: -1;
    width: 100%;
    height: 25rem;
    background-color: #5a270238;
    border-radius: 20% 20% 20% 100%;
    padding-top: 5rem;
    padding-left: 5rem;
    margin-bottom: 2rem;
    display: block;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  
    position: relative;
    -webkit-animation-name: exampleAboutDown; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
    animation-name: exampleAboutDown;
    animation-duration: 8s;
  
  }
  
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes exampleAboutDown {
    0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }
  
  /* Standard syntax */
  @keyframes exampleAboutDown {
    0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
    25%  {background-color:#dfdfdf17; left:0px; top:0px;}
  }

  .about-left{
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    display: block;
    z-index: -1;
  }

  .page-title{
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  

.image-left {
  font-size: 2rem;
  color:white;
  width: 100%;
  text-align: start;
  padding-top: 5rem;
  padding-left: 1rem;
  display: inline;
  
}

.image-right{
  content: none;
  display: none;
}

.Home-section-wd{
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  z-index: -1;
}

.Home-section{
  width: 100%;   
     
}
}
