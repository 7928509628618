.Home-section_l h3,.Home-section_r h3{
    color: rgb(24, 43, 73);
    text-transform: uppercase;
    font-weight: 600;
}





.item-right{
  /* margin-left: 71px; */

}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example4 {
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* Standard syntax */
@keyframes example4 {
  /* 0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  10%   {background-color:#dfdfdf17; top:20px;}
  25%  {background-color:#dfdfdf17;  top:-20px;}
  100%  {background-color:#dfdfdf17; left:0px; top:0px;} */
  0%   {background-color:#dfdfdf17; left:200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
